import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams }             from "react-router-dom";
import { useDispatch, useSelector }           from "react-redux";
import Switch                                 from "react-switch";
import Select                                 from 'react-select';
import { toast }                              from 'react-toastify';

import { createPrescription } from "../redux/actions/prescriptions";
import { getInfoPatient }     from '../redux/actions/patients';
import { apiCall }            from "../redux/api";

import session from "../utils/session";

import convertToDni             from '../middlewares/convertToDni';
import sendSwal                 from '../middlewares/sendSwal'
import sendSwalWithCancel       from '../middlewares/swalWithCancel'
import sendPrescriptionWhatsapp from '../middlewares/sendPrescriptionWhatsapp'
import sendPrescriptionEmail    from '../middlewares/sendPrescriptionEmail'

import { MedicineSelected } from "../components/MedicineSelected";
import { usePrescriotion }  from "../components/PrescriptionProvider";

import Swal from 'sweetalert2'
import '../swal.css';

const NewPrescription = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { prescription } = usePrescriotion();
  
  const params = useParams();

  const [prescriptionData, setPrescriptionData] = useState({
    patient_id: null,
    description: null,
    medical_security: null,
    medical_security_number: null,
    diagnosis: null,
    drugs: null,
    cie10: null,
    psychotropic: null,
    generic: null,
    is_long: null,
    office_id: null,
  });

  const [userId, setUserId]                                         = useState(null);
  const [patientId, setPatientId]                                   = useState(null);
  const [socialSecurity, setSocialSecurity]                         = useState([]);  
  const [inputName, setInputName]                                   = useState("");
  const [inputSurame, setInputSurname]                              = useState("");
  const [inputIdNumber, setInputIdNumber]                           = useState("");
  const [inputMedicalSecurity, setInputMedicalSecurity]             = useState("");
  const [medicalSecurityName, setMedicalSecurityName]               = useState("");
  const [medicalSecurityNumber, setMedicalSecurityNumber]           = useState("");
  const [inputPsychotropic, setInputPsychotropic]                   = useState(0);
  const [inputNoGeneric, setInputNoGeneric]                         = useState(0);
  const [inputIsLong, setInputIsLong]                               = useState(0);
  const [optionsMedicines, setOptionsMedicines]                     = useState([]);
  const [optionsCIE10, setOptionsCIE10]                             = useState([]);
  const [ showErrors, setShowErrors ]                               = useState(false);
  const [ searchMed, setSearchMed ]                                 = useState('');
  const [ timeInputMedicine, setTimeInputMedicine ]                 = useState(0);
  const [ searchCIE10, setSearchCIE10 ]                             = useState('');
  const [ timeInputCIE10, setTimeInputCIE10 ]                       = useState(0);
  const [ hasWhatsApp , setHasWhatsApp ]                            = useState(true);  
  const [ existsAddons, setExistsAddons ]                           = useState(true);
  const [ farmacySend, setFarmacySend ]                             = useState(null);

  const [ selectedOptionDiag, setSelectedOptionDiag] = useState('cie10');

  const previosPage = JSON.parse(localStorage.CurrentUrl);

  const selectInputRefMedicines = useRef();
  const selectInputRefCIE10     = useRef();
  const textRP                  = useRef();

  const { onCreatePrescriptionResult, isLoading, id, error } = useSelector((state) => state.prescriptions);
  
  const { professional: professionalData, secretary: secretaryData, offices } = session.getUser();
  const userData = session.getUser();
  
  useEffect(() => {
    if (professionalData) {
      setUserId(professionalData.id)
    } else if (secretaryData) {
      setUserId(secretaryData.id)
    }
    const data = {
      userId,
    };

    for (let j=0 ; j < offices.length ; j++) {

      if (offices[j].addons == undefined) {
        setExistsAddons(false)
      } else if (offices[j].addons.whatsapp_notifications == 1) {
        setHasWhatsApp(true)
      } else if (offices[j].addons.whatsapp_notifications == 0) {
        setHasWhatsApp(false)
      }

    }

    fromPatient();
    getOOSS();

    setTimeout(() => {
      // Modificación de estado tipo objeto en useEffect inicial 
      // sin el setTimeout no tenía efecto
      onlyOneOffice();
    }, 1);

  }, []);

  useEffect(() => {
    const textarea = textRP.current;
    textarea.style.height = 'auto'; // Resetea la altura para recalcular
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta la altura al scrollHeight
  }, [prescriptionData?.description]); // Se vuelve a ejecutar cada vez que cambia el texto

  useEffect(() => {
    console.log('params', params);
    if (params.id) {
      setPatientId(params.id);
    }
  }, [params]);

  useEffect(() => {
    //TODO Nano: revisar el objeto del storage que no está bien construido
    let storage = JSON.parse(localStorage.CurrentUrl)

    if (onCreatePrescriptionResult == 1 && previosPage.section == "") {

      let mainText = '';

      if (hasWhatsApp) {
        mainText = "¿Enviar esta receta por Whatsapp al paciente?"
      } else {
        mainText = "¿Enviar esta receta por Email al paciente?"
      }
      mainText += showError(error)
      
      sendSwalWithCancel(
        mainText, 
        "Si", 
        "No", 
        () => handleConfirmSend('/prescriptions/', 
        id, 
        hasWhatsApp
      ), () => handleCancelSend('/prescriptions/'), true);
    
    } else if (onCreatePrescriptionResult == 1 && (previosPage.id.section == "prescriptions" || previosPage.id.section == "info" || previosPage.id.section == "events" || previosPage.id.section == "hc" )) { 
      
      let mainText = "¿Enviar esta receta por Whatsapp al paciente?"
      mainText += showError(error)

      sendSwalWithCancel(mainText, "Si", "No", () => handleConfirmSend(`/patients/${storage.id.id}/prescriptions`, id, hasWhatsApp), () => handleCancelSend(`/patients/${storage.id.id}/prescriptions`), true);

    } else  if (onCreatePrescriptionResult == 0) {

      let mainText = "No se pudo crear la receta. Por favor, completa todos los campos."
      let textConfirmButtom = "Completar los datos"
      sendSwal(mainText, textConfirmButtom, "texto cancel", keepInCreatePrescription, false);
    
    } else if (!onCreatePrescriptionResult || onCreatePrescriptionResult == null) {
      return
    }

  }, [onCreatePrescriptionResult]);

  const handleChangeNoGeneric = (checked) => {
    setInputNoGeneric(checked);
    setPrescriptionData({
      ...prescriptionData,
      generic: !checked ? 1 : 0,
    })
  }
  const handleChangePsycho = (checked) => {
    setInputPsychotropic(checked);
    setPrescriptionData({
      ...prescriptionData,
      psychotropic: checked ? 1 : 0,
    })
  }
  const handleChangeIsLong = (checked) => {
    setInputIsLong(checked);
    setPrescriptionData({
      ...prescriptionData,
      is_long: checked ? 1 : 0,
    })
  }

  const handleChange = (e) => {
    setPrescriptionData({
      ...prescriptionData,
      [e.target.name]: e.target.value,
    });  
    
    if (e.target.name == 'office_id') {
      const idx = userData.offices.findIndex((el) => (el.id == e.target.value));
      if (idx >= 0) {
        console.log(idx);
        setFarmacySend({
          email: userData.offices[idx].prescription_data.farmacy_email,
          type: userData.offices[idx].prescription_data.farmacy_send_type ? userData.offices[idx].prescription_data.farmacy_send_type : 'always'
        });
      }
    }
    
  };

  const onlyOneOffice = () => {
    if (userData.offices.length == 1) {
      setPrescriptionData({ ...prescriptionData, office_id: userData.offices[0].id });
      if (userData.offices[0].prescription_data?.farmacy_email) {
        setFarmacySend({
          email: userData.offices[0].prescription_data.farmacy_email,
          type: userData.offices[0].prescription_data.farmacy_send_type ? userData.offices[0].prescription_data.farmacy_send_type : 'always'
        });
      }
      
    }
  }

  const handleClick = async () => {
    // console.log(prescriptionData);
    // Valido datos  
    
    if (!prescriptionData.office_id) {
      setShowErrors(true);
    } else if (prescriptionData.office_id && (prescriptionData.drugs || prescriptionData.description)) {
      
      let auxData = prescriptionData;
      auxData.patient_id = patientId;

      // Borroel dato que sobra
      if (selectedOptionDiag == 'cie10') {        
        delete(auxData.diagnosis);
        console.log(auxData.cie10);
        if (auxData.cie10 && auxData.cie10 != undefined) {
          auxData.cie10 = [auxData.cie10];
        }
      } else {
        delete(auxData.cie10);
      }      

      auxData.medical_security = medicalSecurityName;
      auxData.medical_security_number = medicalSecurityNumber;

      // Verifico si el consultorio tiene configurado el envío automático
      // a la farmacia
      delete(auxData.farmacy_email);
      delete(auxData.farmacy_send);

      if (prescriptionData.drugs) {
        if (farmacySend?.email) {
          if (farmacySend.type == 'ask') {
            const alert = await Swal.fire({
              html: `¿Enviar esta receta a la farmacia?<br /><br />Email: ${farmacySend.email}`,
              showCancelButton: true,
              confirmButtonColor: '#3388FF',
              cancelButtonColor: '#A29EB6',
              confirmButtonText: 'Si',
              cancelButtonText: 'No',
              customClass: {
                confirmButton: 'custom-button-si',
                cancelButton: 'custom-button-no',
                htmlContainer:'text'
              },
            });
            if (alert.value === true) {
              auxData.farmacy_email = farmacySend.email;
              auxData.farmacy_send = 1;
            }
          } else {
            auxData.farmacy_email = farmacySend.email;
            auxData.farmacy_send = 1;
          }
        }
      }

      dispatch(createPrescription(auxData));

    } else {
      let mainText = "Debes elegir al menos un medicamento o completar la descripción."
      let textConfirmButtom = "Completar los datos"

      sendSwal(mainText, textConfirmButtom, "texto cancel", keepInCreatePrescription, false);

    }
    
  }

  const handleClose = () => {

    let storage = JSON.parse(localStorage.CurrentUrl)

    if (storage.id == "") {
      navigate('/prescriptions/');
    } else {
      dispatch(getInfoPatient({ id: storage.id.id }));
      navigate(`/patients/${storage.id.id}/${storage.id.section}`);
    }
    
  };

  const fromPatient = () => {

    setInputName(prescription.patient.name)
    setInputSurname(prescription.patient.surname)
    setInputIdNumber(prescription.patient.id_number)
    if (prescription.patient.medical_security && prescription.patient.medical_security != null) {
      {prescription.patient.medical_security.medical_security_id &&
        setInputMedicalSecurity(prescription.patient.medical_security.medical_security_id)
      }
      {prescription.patient.medical_security.medical_security_name &&
        setMedicalSecurityName(prescription.patient.medical_security.medical_security_name)
      }
      {prescription.patient.medical_security_number
        setMedicalSecurityNumber(prescription.patient.medical_security_number)
      }
    }

    // TODO_G: Ver si lo anterior es necesario o se puede simplificar
  }

  const getOOSS = async () => {
    let auxOOSS = [];
    const auxRes = await apiCall(`/api/medical-securities`, null, null, 'GET');
    if (auxRes?.status == 200) {
      auxOOSS = auxRes.data.data;
    } else {
      toast.error("No se pudo obtener la lista de obras sociales. ERROR: " + auxRes.statusText);
    }
    setSocialSecurity(auxOOSS);
  }
 

  const keepInCreatePrescription = () => {
    console.log("keep")
  }

  const handleInputChangeMedicines = (newValue, actionMeta) => {

    if (timeInputMedicine) clearTimeout(timeInputMedicine);
    setTimeInputMedicine(setTimeout(() => {
      getDataMedicines(newValue);      
    }, 500));

    setSearchMed(newValue);    
  }
  const handleChangeMedicines = (el) => {
    if (!el) { return }

    let auxCurrentMed = (prescriptionData.drugs) ? [ ... prescriptionData.drugs ] : [];
    
    console.log(el);

    let auxObj = {
      id: el.data.id,
      name: el.data.drug,
      desc: '',
      data: el.data,
      count: 1,
      days: '',
      hours: ''
    };

    if (!auxCurrentMed) {
      auxCurrentMed = [];
    }

    auxCurrentMed.push(auxObj);

    setPrescriptionData({ ...prescriptionData, drugs: auxCurrentMed });

    setTimeout(() => {
      selectInputRefMedicines.current.clearValue();   
    }, 1);
    
  }

  const getDataMedicines = async (input) => {
    let auxMed = [];
    if (input) {
      const auxRes = await apiCall(`/api/vademecum?q=${input}`, null, null, 'GET');
      if (auxRes?.status == 200) {
        if (auxRes.data.data) {
          auxRes.data.data.map((el) => {
            auxMed.push({
              label: `${el.drug} ${el.power ? ' - ' + el.power : ''} (${el.content} ${el.drug_type ? ' - ' + el.drug_type : ''})\r\n${el.commercial_name} ${el.laboratory ? ' - ' + el.laboratory : ''}`,
              value: el.id,
              data: el
            });
          });
        }
      } else {
        toast.error("No se pudo obtener el listado de medicamentos. ERROR: " + auxRes.statusText);
      }
    }    
    setOptionsMedicines(auxMed);
  }

  const handleMedicineRemove = (id) => {
    if (!prescriptionData.drugs) { return; }

    const idx = prescriptionData.drugs.findIndex((el) => (el.id == id));
    if (idx >= 0) {
        let auxArr = [ ...prescriptionData.drugs ];
        auxArr.splice(idx, 1);
        setPrescriptionData({ ...prescriptionData, drugs: auxArr });
    }
  }
  const handleMedicineChange = ({ idx, count, days, hours }) => {
    if (!prescriptionData.drugs) { return; }

    let editList = [ ...prescriptionData.drugs];
    let editItem = editList[idx];

    editItem.count = count;
    editItem.days = days;
    editItem.hours = hours;

    setPrescriptionData({ ...prescriptionData, drugs: editList });
  }

  const handleInputChangeCIE10 = (newValue, actionMeta) => {

    if (timeInputCIE10) clearTimeout(timeInputCIE10);
    setTimeInputCIE10(setTimeout(() => {
      getDataCIE10(newValue);      
    }, 250));

    setSearchCIE10(newValue);    
  }
  const handleChangeCIE10 = (el) => {
    if (!el) { return }

    setPrescriptionData({ ...prescriptionData, cie10: el.data });
    
  }

  const getDataCIE10 = async (input) => {
    let auxCIE10 = [];
    if (input) {
      const auxRes = await apiCall(`/api/cie10?q=${input}`, null, null, 'GET');
      if (auxRes?.status == 200) {
        if (auxRes.data.data) {
          auxRes.data.data.map((el) => {
            auxCIE10.push({
              label: el.text,
              value: el.id,
              data: el
            });
          });
        }
      } else {
        toast.error("No se pudo obtener el listado de diagnósticos. ERROR: " + auxRes.statusText);
      }
    }    
    setOptionsCIE10(auxCIE10);
  }

  const handleConfirmSend = (url, id, hasWhatsApp) => {
    if (hasWhatsApp) {
      sendPrescriptionWhatsapp(id);
    } else {
      sendPrescriptionEmail(id);
    }
    
    navigate(url);

  }

  const handleCancelSend = (url, id, hasWhatsApp) => {
    navigate(url);
  }

  const handleOptionDiagChange = (e) => {
    setSelectedOptionDiag(e.target.value);
  };

  const showError = (error) => {
    if (!error) return "";

    error = JSON.parse(error);
    return `<div class='text-red-600 text-sm'><br />Generada con error:<br />${error.description} [Código: ${error.code}]</div>`;
  }

  return (
    
    <div className="z-[10000] bg-[#1C124350] absolute top-0 h-screen w-screen font-sans">
      <div style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px', backgroundColor:'white'}} className="mt-[40px] sm:mt-0 overflow-x-auto h-full">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="flex justify-between px-4 sm:px-0">
              <h3 className="p-4 text-lg text-center font-medium leading-6 text-gray-900"></h3>
              <h3 className="p-4 text-lg text-center font-bold leading-6 text-[#1C1243]">Nueva Receta</h3>
                <h3 onClick={ () => handleClose() } className="mt-2 p-4 text-lg text-center font-medium leading-6 text-gray-900">
                  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                  </svg>              
                </h3>                
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="">
                <div className="bg-white px-4 pb-5">
                  <div className="grid grid-cols-6 gap-2 overflow-y-auto pb-6" style={{ maxHeight:'calc(100vh - 190px)' }}>

                  <div className="sticky top-0 bg-white z-10 pb-2 col-span-6 sm:col-span-3 border-b border-gray-200 text-gray-400 text-sm leading-tight">
                    <div className="block text-[#1C1243] capitalize text-xl font-bold my-1">{inputSurame.toLowerCase()}, {inputName.toLowerCase()}</div>
                    <div className="block">DNI: <span className="font-semibold text-[#1C1243]">{convertToDni(inputIdNumber)}</span></div>
                    <div>Obra Social: <span className="font-semibold text-[#1C1243]">{medicalSecurityName ? medicalSecurityName : 'PARTICULAR'}</span></div>
                    <div>N° de Afiliado: <span className="font-semibold text-[#1C1243]">{medicalSecurityNumber ? medicalSecurityNumber : '--'}</span></div>
                  </div>

                  { userData.offices.length == 1 &&
                    <div className="col-span-6 sm:col-span-3">
                      <div className="text-base font-semibold text-[#1C1243] capitalize">Centro de Salud: {userData.offices[0].name} </div>
                    </div>
                  }

                  { userData.offices.length > 1 && 
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <label htmlFor="office_id" className="block text-base font-semibold text-[#1C1243]">
                        <span>Seleccione el Centro de Salud</span>
                      </label>
                      <select 
                        id="office_id" 
                        name="office_id" 
                        autoComplete="office_id" 
                        className="input-style"
                        required
                        onChange={handleChange}
                      >
                        <option  className="" value="-1">Selecciona el centro de salud</option>
                        {
                            userData && userData.offices.map((el) => (
                                <option key={el.id} value={ el.id }>{ el.name }</option>
                            ))
                        }
                      </select>
                      { 
                        (showErrors && !prescriptionData.office_id) && (
                          <span className="text-red-600 ml-2">Debe seleccionar el consultorio</span>
                        )
                      }
                    </div>
                  }


                  <div className="col-span-6 sm:col-span-3 mt-4">
                    <label htmlFor="office_id" className="block text-base font-semibold text-[#1C1243]">
                      <span>Agregar medicamento</span>
                    </label>
                    <div className="pt-2">
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: 'red',
                            borderRadius: '8px',
                            height: '48px',
                            borderColor: '#d1d5db',
                            fontWeight: '400',
                            fontSize: '16px',
                            boxShadow: 'none !important;'
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            width: 'calc(100% - 2px)',
                            margin: '2px 1px'                            
                          }),
                          // menuList: (baseStyles) => ({
                          //   ...baseStyles,
                          //   margin: '1px'
                          // }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            borderBottom: '1px solid #DDD'
                          })
                        }}
                        ref={ selectInputRefMedicines }
                        placeholder={`Buscar...`}
                        onInputChange={ handleInputChangeMedicines } 
                        onChange={ handleChangeMedicines }
                        options={optionsMedicines}
                        loadingMessage={`Cargando...`}
                        noOptionsMessage={({inputValue}) => !inputValue ? 'Ingrese búsqueda' : (searchMed && timeInputMedicine ? 'Buscando'  : 'Sin resultados para la búsqueda')}
                      />
                    </div>
       

                    {/* { 
                      (showErrors && !prescriptionData.drugs) && (
                        <span className="text-red-600 ml-2">Debe agregar al menos un medicamento</span>
                      )
                    } */}

                    
                  </div>

                  

                  <div className="col-span-6 sm:col-span-3 mt-4">
                    <label htmlFor="description" className="block text-base font-semibold text-[#1C1243]">
                      <span>RP.</span>
                    </label>
                    <div className="flex-col input-style py-0 h-auto">

                      <div className="flex-col my-2">
                        {
                          prescriptionData.drugs && (
                            <div>
                              <div className="flex text-xs text-blue-600">
                                <div>Medicamento</div>
                              </div>
                              {
                                prescriptionData.drugs.map((el, idx) => (
                                  <MedicineSelected 
                                    key={`med-${idx}-${el.value}`} 
                                    item={el} 
                                    idx={idx} 
                                    generic={ !inputNoGeneric } 
                                    remove={ handleMedicineRemove }                                     
                                    onChanged={ handleMedicineChange } 
                                  />
                                ))
                              }
                            </div>
                          )
                        }
                      </div>

                      <textarea 
                        ref={ textRP }
                        type="text" 
                        id="description" 
                        className="input-style border-0 p-0 mt-0 min-h-[120px]"
                        placeholder="Escribe aquí"
                        name="description"
                        onChange={handleChange}
                        disabled={false}
                      />

                    </div>
                    
                  </div>

                  <div className="flex justify-between items-center col-span-6 sm:col-span-3 mt-2">
                    <label htmlFor="diagnosis" className="block text-base font-semibold text-[#1C1243]">
                      <span>Diagnóstico</span>                      
                    </label>
                    <div className="w-full text-center">
                      <div className="flex justify-center items-center gap-6 my-4">
                        <div className="flex justify-center items-center">
                          <input
                            type="radio"
                            id="cie10"
                            name="options"
                            value="cie10"
                            checked={selectedOptionDiag === 'cie10'}
                            onChange={handleOptionDiagChange}
                            className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                          />
                          <label htmlFor="cie10" className="ml-2 text-sm text-gray-700">
                            CIE 10
                          </label>
                        </div>
                        <div className="flex justify-center items-center">
                          <input
                            type="radio"
                            id="manual"
                            name="options"
                            value="manual"
                            checked={selectedOptionDiag === 'manual'}
                            onChange={handleOptionDiagChange}
                            className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                          />
                          <label htmlFor="manual" className="ml-2 text-sm text-gray-700">
                            Manual
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                    
                  <div className="col-span-6 sm:col-span-3">
                    {
                      selectedOptionDiag == 'cie10'
                      ?
                        <Select
                          styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: 'red',
                            borderRadius: '8px',
                            height: '48px',
                            borderColor: '#d1d5db',
                            fontWeight: '400',
                            fontSize: '16px',
                            marginTop: '-0.5rem',
                            boxShadow: 'none !important;'
                            }),
                            menu: (baseStyles) => ({
                              ...baseStyles,
                              width: 'calc(100% - 2px)',
                              margin: '2px 1px'                            
                            }),
                            // menuList: (baseStyles) => ({
                            //   ...baseStyles,
                            //   margin: '1px'
                            // }),
                            option: (baseStyles, state) => ({
                              ...baseStyles,
                              borderBottom: '1px solid #DDD'
                            })
                          }}
                          ref={ selectInputRefCIE10 }
                          placeholder={`Buscar...`}
                          onInputChange={ handleInputChangeCIE10 } 
                          onChange={ handleChangeCIE10 }
                          options={optionsCIE10}
                          loadingMessage={`Cargando...`}
                          menuPlacement={"top"}
                          noOptionsMessage={({inputValue}) => !inputValue ? 'Ingrese búsqueda' : (searchCIE10 && timeInputCIE10 ? 'Buscando'  : 'Sin resultados para la búsqueda')}
                        />
                      :
                        <input 
                          type="text" 
                          id="diagnosis" 
                          className="input-style -mt-2"
                          placeholder="Escribe aquí el diagnóstico"
                          name="diagnosis"
                          onChange={handleChange}
                          disabled={false}
                        />
                    }
                  </div>
                  
                  <div className="col-span-6 sm:col-span-3 mt-6">
                    <div className="flex justify-between items-center block w-full">
                        <div className="text-base font-semibold text-[#1C1243]">Recetar marca comercial</div>
                        <div className="pt-2 ml-4">
                          <Switch name="no_generic" width={46} height={27} onChange={ handleChangeNoGeneric } checked={inputNoGeneric} onColor={"#3388FF"} uncheckedIcon/>
                        </div>  
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <div className="flex justify-between items-center block w-full">
                        <div className="text-base font-semibold text-[#1C1243]">Receta duplicada (Psicofármacos)</div>
                        <div className="pt-2 ml-4">
                          <Switch name="psycho" width={46} height={27} onChange={ handleChangePsycho } checked={inputPsychotropic} onColor={"#3388FF"} uncheckedIcon/>
                        </div>  
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <div className="flex justify-between items-center block w-full">
                        <div className="text-base font-semibold text-[#1C1243]">Tratamiento prolongado</div>
                        <div className="pt-2 ml-4">
                          <Switch name="is_long" width={46} height={27} onChange={ handleChangeIsLong } checked={inputIsLong} onColor={"#3388FF"} uncheckedIcon/>
                        </div>  
                    </div>
                  </div>

                  

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="" className="block text-base font-semibold text-[#1C1243]">
                      <span>Plantillas</span>
                      </label>
                      <select 
                        id="" 
                        name="" 
                        autoComplete="" 
                        className="mt-4 text-base h-[48px] block w-full rounded-2xl border border-gray-300 bg-white py-2 px-3 focus:outline-none"
                        onChange={handleChange}
                      >
                        <option selected value=''>
                          Laboratorio
                        </option>
                        <option selected value=''>
                          Medicamento
                        </option>
                        <option selected value=''>
                          Imagen
                        </option>
                      </select>
                    </div> */}
                </div>
              </div>
                <div className="fixed bottom-0 w-full px-4 py-3 text-right sm:px-6">
                  <button onClick={handleClick} className="submit-button">{(isLoading) ? "Guardando..." : 'Generar Receta'}</button>
                </div>
              </div>
          </div>
        </div>
      </div> 
  </div>
    

    

  );
};

export default NewPrescription;