import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { apiCall } from "../redux/api";
import { toast } from 'react-toastify';
import Select  from 'react-select';
import { usePrescriotion } from "../components/PrescriptionProvider";
import { useAuth } from "../components/AuthProvider";

import convertToDni from "../middlewares/convertToDni";

import 'moment/locale/es';
import Moment from 'react-moment';
import moment from 'moment';
  
export const SelectPatientPrescription = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { dataAppointment, handleSaveAppoinmentData }  = useAuth();

    // const patients = useSelector((state) => patientsResult(state));
    const { handleSelectPatient } = usePrescriotion();

    const [patientId, setPatientId] = useState("");
    const [ optionsPats, setOptionsPats ] = useState([]);
    const [ searchPat, setSearchPat ] = useState('');
    const [ timeInputPatient, setTimeInputPatient ] = useState(0);
    const [ showMessage, setShowMessage ] = useState(false);

    const goToCreate = () => {

        // if ( section == "Turno" ) {
        if (patientId) {
            navigate(`/prescriptions/${patientId}/new`);
        } else {
            setShowMessage(true)
        }

        //} else if ( section == "Receta" ) {
         //   navigate(`/prescriptions/${patientId}/new`);
        //}        
    }

    const handleInputChangePat = (newValue, actionMeta) => {
    
        if (timeInputPatient) {
            clearTimeout(timeInputPatient);            
        }
        setTimeInputPatient(setTimeout(() => {
            getData(newValue);            
        }, 250));
    
        setSearchPat(newValue);    
    }
    const handleChangePat = (el) => {
        if (!el) { return }
        setPatientId(el.data.id);
        handleSelectPatient(el.data);
    }

    const getData = async (input) => {
        let auxPats = [];
        if (input) {
            const auxRes = await apiCall(`/api/patients?search=${input}&limit=50&page=1`, null, null, 'GET');
            if (auxRes?.status == 200) {
                if (auxRes.data.data.data) {
                    auxRes.data.data.data.map((el) => {
                        {el.id_number && 
                            auxPats.push({
                                label: `${el.surname}, ${el.name} - DNI ${el.id_number} `, 
                                value: el.id,
                                data: el
                            });
                        }
                        {!el.id_number && 
                            auxPats.push({
                                label: `${el.surname}, ${el.name}`, 
                                value: el.id,
                                data: el
                            });
                        }
                    });
                }
            } else {
                toast.error("No se pudo obtener el listado de pacientes. ERROR: " + auxRes.statusText);
            }
            setTimeInputPatient(0);
            
        }    
        setOptionsPats(auxPats);
    }

    const handleClose = () => {

        //if ( section == "Turno" ) {
        navigate(`/prescriptions`);
        //} else if ( section == "Receta" ) {
        //    navigate(`/prescriptions/`);
        //}   

    }

    const selectFocus = () => {
        setShowMessage(false);
    }


    return (
        <div>
            <div className="z-[10000] bg-white absolute top-0 h-screen w-screen font-sans">
                <div style={{backgroundColor:'white'}} className="mt-10 px-2 overflow-x-auto h-full">
                <div className="grid grid-cols-2 items-start pb-8">
                {/* <h3 className="text-lg text-center font-medium text-gray-900"></h3> */}
                <h3 className="pl-[10%] w-[350px] text-lg text-[#1C1243]">Nueva receta</h3>
                <button onClick={() => handleClose()}>                
                    <h3 className="pl-[90%] pt-[10px] text-lg font-medium text-[#1C1243]">
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                    </svg>              
                    </h3>
                </button> 
                </div> 
                <div className="mb-4 ml-4 font-sans text-lg font-semibold">Selecciona el paciente</div>
                <div className="mt-2 w-full px-4">
                    <Select
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: 'red',
                                borderRadius: '8px',
                                height: '48px',
                                borderColor: '#d1d5db',
                                fontWeight: '400',
                                fontSize: '16px',
                                textAlign: 'left',
                            }),
                            menu: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: "left",
                            }),
                            noOptionsMessage: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: "left",
                            }),
                            loadingMessage: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: "left",
                            }),
                        }}
                        placeholder={`Buscar...`}
                        onInputChange={ handleInputChangePat } 
                        onChange={ (inputValue) => handleChangePat(inputValue) }
                        onFocus={selectFocus}
                        options={optionsPats}
                        loadingMessage={`Cargando...`}
                        noOptionsMessage={({inputValue}) => !inputValue ? 'Ingrese la búsqueda' : (searchPat && timeInputPatient ? 'Buscando' : 'Sin resultados para la búsqueda')}
                    />
                </div>
                {showMessage && <span className="mx-4 mt-1 text-red-600" >Por favor, selecciona un paciente para avanzar.</span>}
                <div className="px-4 text-center mt-8">
                    <div className="submit-button" onClick={ () => goToCreate() }>Siguiente</div>
                </div>
            </div>
            </div>
        </div>
    )
}