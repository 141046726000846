import React, { useState } from "react";
import { usePersistentState } from "../hooks/usePersistentState";

const PrescriptionContext = React.createContext(null);

export const usePrescriotion = () => {
    return React.useContext(PrescriptionContext);
};

const PrescriptionProvider = ({ children }) => {
    
    const [ prescription, setPrescription] = usePersistentState("prescription", { patient: null });
       
    const handleSelectPatient = (data) => {
      setPrescription({ patient: data })
    }
  
    const value = {
        prescription,
        handleSelectPatient
    };
  
    return (
      <PrescriptionContext.Provider value={value}>
        {children}
      </PrescriptionContext.Provider>
    );
};

export default PrescriptionProvider;