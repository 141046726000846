import { useState, useEffect } from "react";

export const MedicineSelected = ({item, idx, remove, generic = true, onChanged }) => {  
  
  const [ count, setCount ] = useState(1);
  const [ days, setDays ] = useState('');
  const [ hours, setHours ] = useState('');

  const handleChangeCount = (e) => {
    console.log(e.target.value);
    setCount(e.target.value);
  }
  const handleChangeDays = (e) => {
    console.log(e.target.value);
    setDays(e.target.value);
  }
  const handleChangeHours = (e) => {
    console.log(e.target.value);
    setHours(e.target.value);
  }

  useEffect(() => {
    onChanged({ idx, count, days, hours });
  }, [count, days, hours]);
  
  return (
    <div className="border-b py-3 w-full text-sm">
      <div className="flex justify-between items-center leading-tight">
        <div className="flex justify-start items-center w-12">
          <select 
            id="count" 
            name="count" 
            className="input-style w-10 h-[32px] p-0 m-0 text-center text-sm"
            value={count}
            onChange={handleChangeCount}
            >
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>     
          </select>
        </div>
        <div className="grow">
          {/* {`${item.days} días - c/${item.hours}hs.`} */}
          
          { !generic && (<div>{item.data.commercial_name}</div>) }
          
            
          <b>{item.data.drug}</b><br />{item.data.content}          
        </div>
        <div onClick={ () => remove(item.id) } className="flex justify-center items-center border border-[#3388FF] border-dashed bg-white rounded-full min-w-[30px] h-[30px]">
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="#3388FF"></path></svg>          
        </div>       
      </div>
      <div className="text-xs text-blue-600 my-2">Indicaciones</div>
      <div className="flex justify-start items-center">        
        <select 
          id="days" 
          name="days" 
          className="input-style w-24 h-[32px] p-0 m-0 text-center text-sm"
          value={days}
          onChange={handleChangeDays}
          >
            <option value=''></option>
            <option value='1'>1 día</option>
            <option value='2'>2 días</option>
            <option value='3'>3 días</option>
            <option value='4'>4 días</option>
            <option value='5'>5 días</option>
            <option value='6'>6 días</option>
            <option value='7'>7 días</option>
            <option value='8'>8 días</option>
            <option value='9'>9 días</option>
            <option value='10'>10 días</option>
            <option value='11'>11 días</option>
            <option value='12'>12 días</option>
            <option value='13'>13 días</option>
            <option value='14'>14 días</option>
            <option value='15'>15 días</option>
            <option value='16'>16 días</option>
            <option value='17'>17 días</option>
            <option value='18'>18 días</option>
            <option value='19'>19 días</option>
            <option value='20'>20 días</option>
            <option value='21'>21 días</option>
            <option value='22'>22 días</option>
            <option value='23'>23 días</option>
            <option value='24'>24 días</option>
            <option value='25'>25 días</option>
            <option value='26'>26 días</option>
            <option value='27'>27 días</option>
            <option value='28'>28 días</option>
            <option value='29'>29 días</option>
            <option value='30'>30 días</option>          
        </select>
        <select 
          id="hours" 
          name="hours" 
          className="input-style w-28 h-[32px] p-0 m-0 text-center text-sm ml-2"
          value={hours}
          onChange={handleChangeHours}
        >
          <option value=''></option>
          <option value='2'>cada 2 hs.</option>
          <option value='3'>cada 3 hs.</option>
          <option value='4'>cada 4 hs.</option>
          <option value='6'>cada 6 hs.</option>
          <option value='8'>cada 8 hs.</option>
          <option value='12'>cada 12 hs.</option>
          <option value='24'>cada 24 hs.</option>
        </select>
      </div>
    </div>
  )
}