import { useNavigate } from "react-router-dom";
import session from '../utils/session';
import { useAuth } from "../components/AuthProvider";
import { useState, useEffect } from "react";
import PlacesAutocomplete, {    
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';

import { toast } from 'react-toastify';

//import FullHeight from "react-full-height";

export const OnboardingOne = () => {

    const isKeyboardOpen = false; // useDetectKeyboardOpen()
    const [hiddenFooter, setHiddenFooter] = useState("")
    const [address, setAddress] = useState("");
    const [cordinates, setCordinates] = useState({
        lat: null,
        lng: null
    });
    const [addressComponents, setAddressComponents] = useState("")

    const [fieldName, setFieldName] = useState("");
    const [fieldPhone, setFieldPhone] = useState("");
    const [fieldCordinates, setFieldCordinates] = useState("");
    const [fieldAddressComponents, setFieldAddressComponents] = useState("");

    const [errorName, setErrorName] = useState("");
    const [errorAddress, setErrorAddress] = useState("");
    const [errorPhone, setErrorPhone] = useState("");

    const [showStep3, setShowStep3] = useState(true);

    const hHeader = 220;
    const hHeaderNoFooter = hHeader - 80;
    const hFooter = 125;
    const classHeader = `h-[${hHeader}px] sticky -top-[80px] bg-white`; //bg-gray-200
    const classHeaderNoFooter = `h-[${hHeaderNoFooter}px] bg-white`; //bg-gray-200

    const classFooter = `h-[${hFooter}px] sticky -bottom-[${hFooter}px] bg-white`;

    const hFixed = window.innerHeight - hHeader;
    
    const classContent = `min-h-[${hFixed}px] max-h-[${hFixed}px]`;
    const classContentNoFooter= `mt-[${hHeaderNoFooter}px] min-h-[1000px] max-h-[1000px] overflow-y-auto`;
   
    const { token, wizardData, handleSaveWizardStep } = useAuth();
    const userData = {... session.getUser()};
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!token) {
            navigate('/login');
            //window.location.href = '/login'
        }
        
        if (wizardData?.step >= 1) {
            console.log(wizardData.step);
            // llenar campos
            handleSaveWizardStep({
                userId: userData.id,
                office_name: wizardData?.office_name,
                phone: wizardData?.phone,
                address: wizardData?.address,
                address_components: wizardData?.address_components,
                address_cordinates: wizardData?.address_cordinates
            });
        }

        if (wizardData?.countryId != 6) {
            setShowStep3(false);
        }

        getSavedData()
        validateInputs(); 
        //hideFooterWhenAddress();
        
    }, []);

    const hasStretNumber = (results) => {

        if (results[0].address_components[0].types[0] =='street_number' || results[0].address_components[1].types[0] =='street_number' ) {
            setErrorAddress("")
        } else {
            setErrorAddress("El domicilio que has elegido es inexistente. Por favor, corrígelo.")
        }
    }

    const handleSelect = async value => {

        const results = await geocodeByAddress(value);
        const ll = await getLatLng(results[0]);

        hasStretNumber(results);

        // if (errorAddress =="") {

            setAddress(value);
            setCordinates(results[0].geometry);
            setAddressComponents(results[0].address_components);

        // }
 


    }
    
    const goToOnboardingTwo = (data) => {
        navigate(`/onboarding-two`)
    }

    function handleInputNameChange(e) {
        setFieldName(e.target.value);
    }

    function handleInputAddressChange(e) {
        setAddress(e.target.value);
    }

    function handleInputPhoneChange(e) {
        setFieldPhone(e.target.value);
    }



    const getSavedData = () => {

        if (wizardData.office_name != '') {
            setFieldName(wizardData.office_name);
        } 
        if (wizardData.address != '') {
            setAddress(wizardData.address);
        }
        if (wizardData.phone != '') {
            setFieldPhone(wizardData.phone);
        } 
        
        if (wizardData.address_cordinates != '') {
            setFieldCordinates(wizardData.address_cordinates);
        }
        
        if (wizardData.address_components != '') {
            setFieldAddressComponents(wizardData.address_components);
        }  

    }
        
    const validateInputs = () => {
        let inputName = document.querySelector('#office_name');
        let inputAddress = document.querySelector('#address');
        let inputPhone = document.querySelector('#phone');
        let regexPhone = /[0-9]/;
        
        //Validación Campo Nombre
        inputName.addEventListener('focus', function () {
            if (inputName.value == "") {
                setErrorName('El campo nombre no debe estar vacío');
            }
        })
        inputName.onkeydown  = function () {
            setErrorName('');          
        }
    
        inputName.onblur  = function () {
            if (inputName.value.length < 2 ) {
                setErrorName('El campo nombre de poseer al menos 2 caracteres');  
            } else {
                setErrorName('');
            } 
        }
        //Validación Campo Domicilio
        inputAddress.addEventListener('focus', function () {
            if (inputAddress.value == "") {
                setErrorAddress('El campo nombre no debe estar vacío');
            }


        })

        //Validación Campo Teléfono
        inputPhone.addEventListener('focus', function () {
            if (inputPhone.value == "") {
                setErrorPhone('El campo teléfono no debe estar vacío');
            }
        })
        inputPhone.onkeydown  = function () {
            setErrorPhone('');          
        }
    
        inputPhone.onblur  = function () {
            if (!regexPhone.test(inputPhone.value)) {
                setErrorPhone('Solo puedes ingresar valores numéricos');  
            } else if (inputPhone.value.length < 10 ) {
                setErrorPhone('El campo teléfono de poseer al menos 10 caracteres');
            } else {
                setErrorPhone('');
            }
        }
        
    }
    
    const submitHandler = e => {
        e.preventDefault();
        
        const office_name= e.target.office_name.value;
        const address= e.target.address.value;
        const phone= e.target.phone.value;  
        
        if (office_name =='' || address =='' || phone == '' ) {
            setErrorPhone('Por favor, completa los campos');
            return
        }
        if (errorName == '' && errorAddress == '' && errorPhone == '') {
            wizardData.id = userData.id;
            if (wizardData?.step) {
                wizardData.step = Math.max(1, wizardData.step);
            }

            if (address == wizardData.address) {
                wizardData.office_name = office_name
                wizardData.address = address
                wizardData.phone = phone
            } else {
                wizardData.office_name = office_name
                wizardData.address = address
                wizardData.address_cordinates = cordinates
                wizardData.phone = phone
                wizardData.address_components = addressComponents
            }

            handleSaveWizardStep(wizardData);
            goToOnboardingTwo();

        }
    }

    // const hideFooter = () => {
    //     setHiddenFooter("hidden")
    // }

    // const showFooter = () => {
    //     setHiddenFooter("")
    // }

    // const hideFooterWhenAddress = () => {
    //     let inputAddress = document.querySelector('#address');

    //     inputAddress.onblur  = function () {
    //         showFooter();
    //     }

    // }

  return (
    <>
    <div className={`w-full ${isKeyboardOpen ? classHeaderNoFooter : classHeader } px-[35px]`}>
        <h2 className={`font-sans pt-[100px] text-center text-2xl font-bold tracking-tight text-[#3388FF] leading-tight`}>Datos básicos de</h2>
        <h2 className="font-sans text-center text-2xl font-bold tracking-tight text-[#3388FF] leading-tight">tu centro de salud</h2>
        <h4 className="font-sans mt-2 leading-5 text-center text-[17px] font-semibold tracking-tight text-gray-400">Luego podrás cambiarlos</h4>
    </div>
    <div className="absolute top-[30px] left-[15px]">
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.45711 0.292893C7.84763 0.683418 7.84763 1.31658 7.45711 1.70711L3.16421 6L7.45711 10.2929C7.84763 10.6834 7.84763 11.3166 7.45711 11.7071C7.06658 12.0976 6.43342 12.0976 6.04289 11.7071L1.04289 6.70711C0.855357 6.51957 0.75 6.26522 0.75 6C0.75 5.73478 0.855357 5.48043 1.04289 5.29289L6.04289 0.292893C6.43342 -0.0976311 7.06658 -0.0976311 7.45711 0.292893Z" fill="none"/>
    </svg>
    </div>
    <form onSubmit={submitHandler} className={`${classContent} flex flex-col m-0 px-0 w-full font-sans`} method="POST">                                        
        <div className='w-auto px-[30px]'>       
            <div className="my-4">
                <label htmlFor="office_name" className="pt-3 pb-2 block text-[16px] font-bold text-gray-700">Nombre del Centro de Salud</label>
                <div className="mt-2 flex rounded-2xl shadow-sm">
                    <span className="inline-flex items-center rounded-l-xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.75002 1.75C5.88313 1.75 4.41669 3.20257 4.41669 4.9375C4.41669 6.67243 5.88313 8.125 7.75002 8.125C9.61691 8.125 11.0834 6.67243 11.0834 4.9375C11.0834 3.20257 9.61691 1.75 7.75002 1.75ZM2.91669 4.9375C2.91669 2.32319 5.10659 0.25 7.75002 0.25C10.3935 0.25 12.5834 2.32319 12.5834 4.9375C12.5834 7.55181 10.3935 9.625 7.75002 9.625C5.10659 9.625 2.91669 7.55181 2.91669 4.9375ZM10.4162 12.9684C8.64987 12.6772 6.85013 12.6772 5.08382 12.9684L4.87605 13.0027L4.75404 12.2627L4.87604 13.0027C2.94882 13.3204 1.5 15.0477 1.5 17.1115C1.5 17.7634 2.00549 18.25 2.57813 18.25H12.9219C13.4945 18.25 14 17.7634 14 17.1115C14 15.0477 12.5512 13.3204 10.624 13.0027L10.746 12.2627L10.624 13.0027L10.4162 12.9684ZM10.6602 11.4884L10.5382 12.2284L10.6602 11.4884L10.868 11.5226C13.5542 11.9655 15.5 14.349 15.5 17.1115C15.5 18.5455 14.3685 19.75 12.9219 19.75H2.57813C1.13147 19.75 0 18.5455 0 17.1115C0 14.349 1.94583 11.9655 4.63203 11.5226L4.83981 11.4884L4.96182 12.2284L4.83982 11.4884C6.7677 11.1705 8.73229 11.1705 10.6602 11.4884Z" fill="#6b7280"/>
                        </svg>
                    </span>
                    <input 
                       
                        type="text" 
                        name="office_name" 
                        className="text-base block flex-1 rounded-r-xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                        id='office_name'
                        value={fieldName}
                        onChange={handleInputNameChange}
                        //onFocus={hideFooter}
                        //onBlur={showFooter}
                    />
                </div>
                {errorName && 
                    <span className="mx-1 mt-1 text-red-600" >{errorName}</span>
                }
            </div>
            <div className="my-4">
                <label htmlFor="address" className="pt-3 pb-2 block text-[16px] font-bold text-gray-700">Domicilio</label>
                <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <div className="mt-2 flex rounded-2xl">
                        <span className="inline-flex items-center rounded-l-xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 7.78571C0 3.62779 3.35309 0.25 7.49809 0.25C11.6431 0.25 14.9962 3.62779 14.9962 7.78571C14.9962 9.80439 13.7463 12.3513 11.5163 15.3562L11.5116 15.3625L10.9746 16.0703C10.3202 16.9152 9.58595 17.803 8.77263 18.7337L8.76912 18.7377L8.0593 19.5383C7.91755 19.6982 7.71433 19.79 7.50066 19.7908C7.28699 19.7915 7.08315 19.7011 6.94031 19.5421C5.83624 18.3138 4.86291 17.1564 4.02157 16.0702L4.01695 16.0643L3.47985 15.3562C1.24984 12.3513 0 9.80439 0 7.78571ZM7.49809 1.75C4.18936 1.75 1.5 4.44835 1.5 7.78571C1.5 9.27782 2.47088 11.4789 4.68209 14.4591L5.20973 15.1547C5.881 16.0211 6.64328 16.9399 7.49726 17.9112L7.64317 17.7466C7.64374 17.746 7.6443 17.7453 7.64487 17.7447C8.4401 16.8346 9.15374 15.9714 9.78641 15.1547L10.3141 14.4591C12.5253 11.4789 13.4962 9.27783 13.4962 7.78571C13.4962 4.44835 10.8068 1.75 7.49809 1.75Z" fill="#6b7280"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.49811 5.68639C6.35946 5.68639 5.4364 6.60945 5.4364 7.7481C5.4364 8.88674 6.35946 9.8098 7.49811 9.8098C8.63676 9.8098 9.55981 8.88674 9.55981 7.7481C9.55981 6.60945 8.63676 5.68639 7.49811 5.68639ZM3.9364 7.7481C3.9364 5.78102 5.53103 4.18639 7.49811 4.18639C9.46518 4.18639 11.0598 5.78102 11.0598 7.7481C11.0598 9.71517 9.46518 11.3098 7.49811 11.3098C5.53103 11.3098 3.9364 9.71517 3.9364 7.7481Z" fill="#6b7280"/>
                            </svg>
                        </span>
                        <input
                            type="text" 
                            name="address"
                            id="address"
                            value={address}
                            //onFocus={hideFooter}
                            //onBlur={showFooter}                         
                            style={ {
                                outline: 'none',
                                fontSize: '1rem', 
                                //'border-radius': '1rem', 
                                borderTopRightRadius: '0.75rem',
                                borderBottomRightRadius: '0.75rem',
                                width: '100vh', 
                                height:'48px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor:' rgb(209 213 219)',
                                fontFamily: 'Open Sans',
                                borderLeft: '0rem solid',
                                backgroundColor: 'white'
                            }}
                            {...getInputProps({
                                placeholder: '',
                                className: 'location-search-input',
                            })}
                        />
                        </div>
                        <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, idx) => {
                            const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                            <div
                                key={`suggestion-${idx}`}
                                {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                                })}
                            >
                                <span>{suggestion.description}</span>
                            </div>
                            );
                        })}
                        </div>
                    </div>
                    )}
                </PlacesAutocomplete>
                {errorAddress != "" && 
                    <span className="mx-1 mt-1 text-red-600" >{errorAddress}</span>
                }
            </div>
            <div className="my-4 pb-10">
                <label htmlFor="phone" className="pt-3 pb-2 block text-[16px] font-bold text-gray-700">Teléfono Comercial</label>
                <div className="mt-2 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-xl border border-r-0 border-gray-300 px-3 text-sm text-gray-500">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.36991 0.41472C3.71926 0.305635 4.08171 0.25 4.44661 0.25C6.20055 0.25 7.50001 1.79446 7.5 3.54107L7.5 7.45894C7.50001 9.20554 6.20055 10.75 4.44661 10.75C4.0817 10.75 3.71926 10.6944 3.36991 10.5853L3.10287 10.5019C3.01168 10.4734 2.92197 10.4418 2.83382 10.4073C4.22511 13.2328 6.51723 15.5249 9.34271 16.9162C9.30816 16.828 9.27658 16.7383 9.24811 16.6471L9.16472 16.3801C9.05564 16.0307 9 15.6683 9 15.3034C9 13.5494 10.5445 12.25 12.2911 12.25H16.2089C17.9555 12.25 19.5 13.5494 19.5 15.3034C19.5 15.6683 19.4444 16.0307 19.3353 16.3801L19.2519 16.6471C18.7662 18.2027 17.3768 19.3293 15.7148 19.6221C14.7472 19.7926 13.7528 19.7926 12.7852 19.6221C12.7471 19.6154 12.7091 19.6083 12.6714 19.6007C6.32975 18.3806 1.36938 13.4202 0.149306 7.07856C0.141731 7.0408 0.134588 7.00289 0.127883 6.96484C-0.0426279 5.99719 -0.0426274 5.00281 0.127883 4.03516C0.420739 2.37321 1.54728 0.983837 3.10286 0.498107C3.10286 0.498107 3.10287 0.498106 3.10287 0.498106L3.36991 0.414722L3.36991 0.41472ZM1.62147 6.7909C1.61574 6.76107 1.61011 6.73122 1.60456 6.70133C1.46496 5.9069 1.46515 5.08984 1.60512 4.29547C1.81162 3.12362 2.5876 2.23042 3.54995 1.92993L3.54996 1.92993L3.81699 1.84654C3.817 1.84654 3.817 1.84654 3.817 1.84654C4.0228 1.78228 4.23455 1.75 4.44661 1.75C5.23693 1.75 6.00001 2.48089 6 3.54106L6 7.45894C6 8.51911 5.23693 9.25 4.44661 9.25C4.23455 9.25 4.02279 9.21772 3.81699 9.15346L3.54995 9.07007C2.61138 8.77701 1.85009 7.92017 1.62147 6.7909ZM13.0486 18.1454C13.8431 18.285 14.6601 18.2849 15.4545 18.1449C16.6264 17.9384 17.5196 17.1624 17.8201 16.2001L17.9035 15.933C17.9677 15.7272 18 15.5155 18 15.3034C18 14.5131 17.2691 13.75 16.2089 13.75H12.2911C11.2309 13.75 10.5 14.5131 10.5 15.3034C10.5 15.5155 10.5323 15.7272 10.5965 15.933L10.6799 16.2C10.973 17.1386 11.8299 17.8999 12.9592 18.1285C12.9889 18.1343 13.0188 18.1399 13.0486 18.1454Z" fill="#6b7280"/>
                        </svg>
                    </span>
                    <input 
                        type="text" 
                        name="phone" 
                        className="text-base block flex-1 rounded-r-xl border border-l-0 border-gray-300 px-3 py-2 h-[48px] focus:outline-none" 
                        id="phone"
                        value={fieldPhone}
                        onChange={handleInputPhoneChange}
                        //onFocus={() => hideFooter()}
                        //onBlur={() => showFooter()}
                    />
                </div>
                {errorPhone && 
                    <span className="mx-1 mt-1 text-red-600" >{errorPhone}</span>
                }
            </div>
                                                         
        </div>
        <div className={`${isKeyboardOpen ? "hidden" : ""} font-sans w-full flex justify-between mt-0 py-2 px-5 ${classFooter}`}>
            <div className="flex items-center px-2">
                <div className="w-[24px] h-[8px] bg-[#3388FF] rounded-[100px] mr-2"></div>
                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                { showStep3 && (<div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>) } 
                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
                <div className="w-[8px] h-[8px] bg-[#D1D8DD] rounded-full mr-2"></div>
            </div>
            <div className="flex items-center">
                <span className="mr-4 text-base text-gray-400 font-bold">Siguiente</span>
                <span>
                    <button type="submit" className="justify-center rounded-full w-[60px] h-[60px] border border-transparent bg-[#3388FF] py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <svg className="ml-2 font-semibold" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.542893 11.7071C0.152369 11.3166 0.152369 10.6834 0.542893 10.2929L4.83579 6L0.542893 1.70711C0.152368 1.31658 0.152368 0.683418 0.542892 0.292894C0.933417 -0.0976309 1.56658 -0.097631 1.95711 0.292894L6.95711 5.29289C7.14464 5.48043 7.25 5.73478 7.25 6C7.25 6.26522 7.14464 6.51957 6.95711 6.70711L1.95711 11.7071C1.56658 12.0976 0.933417 12.0976 0.542893 11.7071Z" fill="white"/>
                        </svg>
                    </button>
                </span>
            </div>
        </div>   
                        
    </form>       
    
    </>
  )
}